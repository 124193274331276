import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PaymentSuccessComponent } from './core/components/payment-success/payment-success.component';
import { DirectoryComponent } from './core/components/directory/directory.component';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { TenantGuard } from './core/guards/tenant.guard';

const routes: Routes = [
  {
    path: '', redirectTo: 'not-found', pathMatch: 'full'
  },
  {
    path: 'payment-success', component: PaymentSuccessComponent
  },
  {
    path: 'not-found', component: NotFoundComponent
  },
  {
    path: 'directory', component: DirectoryComponent
  },
  {
    path: ':tenantId', canActivate: [TenantGuard], canActivateChild: [TenantGuard], children: [
      {
        path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'events', loadChildren: () => import('./events/events.module').then(m => m.EventsModule)
      },
      {
        path: 'store', loadChildren: () => import('./merch/merch.module').then(m => m.MerchModule)
      },
      {
        path: 'buy', loadChildren: () => import('./buy/buy.module').then(m => m.BuyModule)
      },
      {
        path: 'profile', loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
