import {
    ActionReducer,
    ActionReducerMap,
    createFeatureSelector,
    createSelector,
    MetaReducer
} from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { environment } from '../../../environments/environment';
import * as fromLogin from './reducers/login.reducer';
import * as fromOrganization from './reducers/organization.reducer';
import * as fromTenant from './reducers/tenant.reducer';


export interface State {
    organization: fromOrganization.OrganizationState,
    user: fromLogin.UserState,
    tenant: fromTenant.TenantState
};

export const reducers: ActionReducerMap<State> = {
    organization: fromOrganization.reducer,
    user: fromLogin.reducer,
    tenant: fromTenant.reducer
};

const reducerKeys = ['organization'];

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({ keys: reducerKeys })(reducer);
};

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {

        return reducer(state, action);
    };
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [debug, localStorageSyncReducer] : [localStorageSyncReducer];

export const getLoginState = createFeatureSelector<fromLogin.UserState>('user');
export const getOrganizationState = createFeatureSelector<fromOrganization.OrganizationState>('organization');
export const getTenantState = createFeatureSelector<fromTenant.TenantState>('tenant');


export const getLoggedInUser = createSelector(
    getLoginState,
    fromLogin.getLoggedInUser
);

export const getOrganization = createSelector(
    getOrganizationState,
    fromOrganization.getOrganization
);

export const getTenant = createSelector(
    getTenantState,
    fromTenant.getTenant
);