import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import * as Sentry from '@sentry/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './core/store';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TrackingService } from './core/services/tracking.service';

export function initializeApp(trackingService: TrackingService) {
  return (): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      trackingService.ensureTrackingId();
      resolve();
    });
  };
}
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DialogModule } from 'primeng/dialog';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
    FontAwesomeModule,
    DialogModule,
    NgxIntlTelInputModule,
    BrowserAnimationsModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [TrackingService],
      multi: true
    }, {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
