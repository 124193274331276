import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
  debug: true,
  environment: environment.production ? 'production' : 'development',
  dsn: "https://1c5504e883cb4c7f9c78d8ecdf30622d@o4504651545640960.ingest.sentry.io/4504788728872960",
  tunnel: environment.sentry.tunnel,
  integrations: [
    
  ],
  tracesSampleRate: 1.0,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
