export const environment = {
  production: true,
  domain: 'my.nevent.es',
  appName: 'my',
  appBaseUrl: 'https://my.nevent.es',
  apiUrl: 'https://api.nevent.es',
  auth: {
    url: 'https://auth.nevent.es/auth'
  },
  profile: {
    url: 'https://profile.nevent.es/profile'
  },
  info: {
    terms: 'https://nevent.es/terms/'
  },
  social: {
    instagram: 'nevent.es',
    twitter: 'nevent_es'
  },
  maps: {
    apiKey: 'AIzaSyCP0bPgMQBjO6HhKGK9B9qKCc8zX7Wm6Rk'
  },
  sentry: {
    tunnel: "https://api.nevent.es/diagnostics"
  },
  cookie: {
    path: '/',
    origin: 'nevent.es',
    secure: true,
    sameSite: 'Lax',
    maxAge: 86400
  },
  stripe: {
    pk: 'pk_live_51LZyyHFwCCyD83ICXEX4AZ5RVoYKIOEqJikk1V0qVbKjXvsHPTVMu4bnzaug4ZzcknwEv672AvQbcOkXAz9ShyXB00A9nSIihG'
  },
  google: {
    analytics: {
      key: 'G-FEE0D1FZC5'
    },
    tagManager:{
      key: 'GTM-KGT9C9D4'
    }
  }
};