import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, catchError, take, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { State, Store } from "@ngrx/store";
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class WithCredentialsInterceptor implements HttpInterceptor {

  constructor(private state: State<any>, private cookieService: CookieService) {
  }

  private static setSessionId(req: HttpRequest<any>, sessionId: string): HttpRequest<any> {

    if (sessionId && sessionId !== '' && !req.url.includes('amazonaws.com')) {

      const headers = req.headers.set('X-SessionID', sessionId);
      return req.clone({ headers });
    } else {

      return req;
    }
  }

  private static setAuthorizationHeader(req: HttpRequest<any>, accessToken: string, idToken: string): HttpRequest<any> {

    if (accessToken && accessToken !== '' && !req.url.includes('amazonaws.com')) {

      const authorizationToken = `Bearer ${accessToken}`;
      const headers = req.headers.set('Authorization', authorizationToken).set('X-ID-TOKEN', idToken);
      return req.clone({ headers });
    } else {

      return req;
    }
  }

  private static setReferer(req: HttpRequest<any>, tenant: string): HttpRequest<any> {

    if (tenant) {

      const headers = req.headers.set('X-Referer', tenant);
      return req.clone({ headers });
    } else {

      return req;
    }
  }

  private static setAffiliate(req: HttpRequest<any>, affiliate: string): HttpRequest<any> {

    if (affiliate) {

      const headers = req.headers.set('X-Affiliate-Code', affiliate);
      return req.clone({ headers });
    } else {

      return req;
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {

    const authorizedRequest = this.setHeaders(request);
    const handledRequest = next.handle(authorizedRequest);
    // @ts-ignore
    const interceptionOperator = catchError(error => {
      if (error.error instanceof ErrorEvent) {
        // client-side error
      } else {
        // server-side error
        if (error.status === 401 || error.status === 403) {
          let origin: string = window.location.href;

          if (localStorage.getItem('origin')) {
            origin = localStorage.getItem('origin');
          }

          // no force login to purchase
          //window.location.replace(`${environment.auth.url}?appName=${environment.appName}&origin=${origin}`);
        } else {
          return throwError(error);
        }
      }
    });
    return handledRequest.pipe(interceptionOperator);
  }

  private setHeaders = (req: HttpRequest<any>): HttpRequest<any> => {

    const accessToken = this.cookieService.get('access_token');
    const idToken = this.cookieService.get('id_token');
    const sessionId = sessionStorage.getItem('sessionId');

    const tenant = this.state.getValue()?.tenant;

    req = WithCredentialsInterceptor.setAuthorizationHeader(req, accessToken, idToken);
    req = WithCredentialsInterceptor.setSessionId(req, sessionId);
    req = WithCredentialsInterceptor.setReferer(req, tenant);
    req = WithCredentialsInterceptor.setAffiliate(req, sessionStorage.getItem('X-Affiliate-Code'))

    return req;
  }

}
