import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  private trackingKey = 'neventCustomerId';

  constructor() {
    this.ensureTrackingId();
  }

  public ensureTrackingId(): void {
    if (!this.getTrackingId()) {
      const trackingId = uuidv4();
      this.setTrackingId(trackingId);
    }
  }

  public getTrackingId(): string {
    return localStorage.getItem(this.trackingKey) || this.getCookie(this.trackingKey);
  }

  private setTrackingId(trackingId: string): void {
    localStorage.setItem(this.trackingKey, trackingId);
    this.setCookie(this.trackingKey, trackingId, 365);
  }

  private setCookie(name: string, value: string, days: number): void {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/`;
  }

  private getCookie(name: string): string | null {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
}
