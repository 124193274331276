<div class="card mb-3">
  <a [href]="organiser.domain">
    <div class="flex flex-row justify-content-start align-items-center">
      <div class="flex-none flex flex-column image">
        <img [src]="organiser.logoUrl" />
      </div>
      <div class="flex-grow-1 flex flex-column ml-3">
        <div class="flex-row justify-content-start align-items-center">
          <div>
            <h2>{{ organiser.name }}</h2>
          </div>
          <div
            class="social-icons flex align-items-center justify-content-start"
          >
            <a
              *ngIf="organiser?.web"
              [href]="organization?.web"
              target="_blank"
            >
              <button pButton type="button" class="p-button-text">
                <fa-icon [icon]="faGlobe" class="web"></fa-icon>
                <span class="ml-2">{{ "FOLLOW_US" | translate }}</span>
              </button>
            </a>
            <a
              *ngIf="organiser?.facebook"
              [href]="'https://www.facebook.com/' + organiser?.facebook"
              target="_blank"
            >
              <button pButton type="button" class="p-button-text">
                <fa-icon [icon]="faFacebook" class="facebook"></fa-icon>
                <span class="ml-2">{{ "FOLLOW_US" | translate }}</span>
              </button>
            </a>
            <a
              *ngIf="organiser?.instagram"
              [href]="'https://www.instagram.com/' + organiser?.instagram"
              target="_blank"
            >
              <button pButton type="button" class="p-button-text">
                <fa-icon [icon]="faInstagram" class="instagram"></fa-icon>
                <span class="ml-2">{{ "FOLLOW_US" | translate }}</span>
              </button>
            </a>
            <a
              *ngIf="organiser?.spotify"
              [href]="'https://open.spotify.com/' + organiser?.spotify"
              target="_blank"
            >
              <button pButton type="button" class="p-button-text">
                <fa-icon [icon]="faSpotify" class="spotify"></fa-icon>
                <span class="ml-2">{{ "FOLLOW_US" | translate }}</span>
              </button>
            </a>
            <a
              *ngIf="organiser?.tiktok"
              [href]="'https://www.tiktok.com/@' + organiser?.tiktok"
              target="_blank"
            >
              <button pButton type="button" class="p-button-text">
                <fa-icon [icon]="faTiktok" class="tiktok"></fa-icon>
                <span class="ml-2">{{ "FOLLOW_US" | translate }}</span>
              </button>
            </a>
            <a
              *ngIf="organiser?.twitch"
              [href]="'https://www.twitch.tv/' + organiser?.twitch"
              target="_blank"
            >
              <button pButton type="button" class="p-button-text">
                <fa-icon [icon]="faTwitch" class="twitch"></fa-icon>
                <span class="ml-2">{{ "FOLLOW_US" | translate }}</span>
              </button>
            </a>
            <a
              *ngIf="organiser?.youtube"
              [href]="'https://www.youtube.com/@' + organiser?.youtube"
              target="_blank"
            >
              <button pButton type="button" class="p-button-text">
                <fa-icon [icon]="faYoutube" class="youtube"></fa-icon>
                <span class="ml-2">{{ "FOLLOW_US" | translate }}</span>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </a>
</div>
