import { Action, createReducer, on } from '@ngrx/store';
import { TenantInfo } from 'src/app/core/model/tenant-info.interface';
import * as actions from '../actions';

export interface OrganizationState {
  organization: TenantInfo
}

export const initialState: OrganizationState = undefined;

const organizationReducer = createReducer(
  initialState,
  on(actions.organizationSuccess, (state, organization) => {

    return organization.organization;
  })
);

export function reducer(state: OrganizationState | undefined, action: Action): any {

  return organizationReducer(state, action);
};

export const getOrganization = (state: OrganizationState) => {

  return state.organization;
};