<div class="footer p-4">
  <div class="flex flex-row align-items-start justify-content-evenly flex-wrap">
    <div class="flex flex-column">
      <div class="flex flex-row align-items-start justify-content-start">
        <p>© Copyright 2022-2024</p>
      </div>
    </div>
    <div class="flex flex-column">
      <h4>{{ "USEFUL_LINKS" | translate }}</h4>
      <div class="p-2">
        <a href="https://nevent.es">Nevent</a>
      </div>
      <div class="p-2">
        <a [href]="terms">{{ "TERMS_CONDITIONS" | translate }}</a>
      </div>
      <div class="p-2">
        <a [routerLink]="['directory']">{{ "EVENT_DIRECTORY" | translate }}</a>
      </div>
    </div>
    <div class="flex flex-column">
      <h4>{{ "FOLLOW_US" | translate }}</h4>
      <div class="social-icons flex align-items-center justify-content-center">
        <a *ngIf="organization?.web" [href]="organization?.web" target="_blank">
          <button pButton type="button" class="p-button-text">
            <fa-icon [icon]="faGlobe" class="web"></fa-icon>
          </button>
        </a>
        <a
          *ngIf="organization?.facebook"
          [href]="'https://www.facebook.com/' + organization?.facebook"
          target="_blank"
        >
          <button pButton type="button" class="p-button-text">
            <fa-icon [icon]="faFacebook" class="facebook"></fa-icon>
          </button>
        </a>
        <a
          *ngIf="organization?.instagram"
          [href]="'https://www.instagram.com/' + organization?.instagram"
          target="_blank"
        >
          <button pButton type="button" class="p-button-text">
            <fa-icon [icon]="faInstagram" class="instagram"></fa-icon>
          </button>
        </a>

        <a
          *ngIf="organization?.spotify"
          [href]="'https://open.spotify.com/' + organization?.spotify"
          target="_blank"
        >
          <button pButton type="button" class="p-button-text">
            <fa-icon [icon]="faSpotify" class="spotify"></fa-icon>
          </button>
        </a>

        <a
          *ngIf="organization?.tiktok"
          [href]="'https://www.tiktok.com/@' + organization?.tiktok"
          target="_blank"
        >
          <button pButton type="button" class="p-button-text">
            <fa-icon [icon]="faTiktok" class="tiktok"></fa-icon>
          </button>
        </a>

        <a
          *ngIf="organization?.twitch"
          [href]="'https://www.twitch.tv/' + organization?.twitch"
          target="_blank"
        >
          <button pButton type="button" class="p-button-text">
            <fa-icon [icon]="faTwitch" class="twitch"></fa-icon>
          </button>
        </a>
        <a
          *ngIf="organization?.youtube"
          [href]="'https://www.youtube.com/@' + organization?.youtube"
          target="_blank"
        >
          <button pButton type="button" class="p-button-text">
            <fa-icon [icon]="faYoutube" class="youtube"></fa-icon>
          </button>
        </a>
      </div>
    </div>
  </div>
</div>
