import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserConfigured } from 'src/app/core/model/user-configured.interface';
import { environment } from 'src/environments/environment';
import { TenantInfo } from '../model/tenant-info.interface';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getTenantInfo(): Observable<TenantInfo> {

    const url = `${this.baseUrl}/public/tenant`;

    return this.http.get<TenantInfo>(url);
  }

  getExternalTenantInfo(): Observable<TenantInfo> {

    const url = `${this.baseUrl}/public/tenant/external`;

    return this.http.get<TenantInfo>(url);
  }

  isUserConfigured(): Observable<UserConfigured> {

    const url = `${this.baseUrl}/public/user`;

    return this.http.get<UserConfigured>(url);
  }

  confirmPayment(paymentIntentId: string) {

    const url = `${this.baseUrl}/cart/confirm?paymentIntentId=${paymentIntentId}`;

    return this.http.get<any>(url);
  }

}
