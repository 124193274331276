import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from "@ngrx/store";
import { tenantSuccess } from 'src/app/core/store/actions';

@Injectable({
  providedIn: 'root'
})
export class TenantGuard {

  constructor(private store: Store, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.evaluateTenant(state);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.evaluateTenant(state);
  }

  private evaluateTenant(state: RouterStateSnapshot) {

    const url = new URL(window.location.href);

    const pathParts = url.pathname.split('/');

    if (pathParts.length === 0) {

      this.router.navigate['not-found'];
    }

    let tenant = pathParts[1];

    if (tenant === 'undefined' || tenant === 'events' || tenant === 'profile' || tenant === 'merch' || tenant === 'directory') {
      tenant = undefined;
    }

    if (!tenant) {
      this.router.navigate(['/not-found'])
    }

    this.store.dispatch(tenantSuccess({ tenant: tenant }));
    localStorage.setItem('tenantId', tenant);

    return true;
  }
}