import { Component, OnDestroy, OnInit } from '@angular/core';
import { faFacebookF, faInstagram, faSpotify, faTiktok, faTwitch, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Subscription } from 'rxjs/internal/Subscription';
import { environment } from 'src/environments/environment';
import { TenantInfo } from '../../model/tenant-info.interface';
import { State } from '@ngrx/store';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  state$: Subscription;
  organization: TenantInfo;
  faGlobe = faGlobe;
  faInstagram = faInstagram;
  faSpotify = faSpotify;
  faTiktok = faTiktok;
  faTwitch = faTwitch;
  faFacebook = faFacebookF;
  faYoutube = faYoutube;
  faTwitter = faTwitter;
  terms: string;
  instagram: string;
  twitter: string;

  constructor(private state: State<any>) { }

  ngOnInit(): void {
    this.terms = environment.info.terms;
    this.instagram = environment.social.instagram;
    this.twitter = environment.social.twitter;
    this.state$ = this.state.subscribe(state => {
      if (state.organization) {
        this.organization = state.organization;
      }
    });
  }

  ngOnDestroy(): void {

    if (this.state$) {
      this.state$.unsubscribe();
    }

  }

}
