import { Action, createReducer, on } from '@ngrx/store';
import { TenantInfo } from 'src/app/core/model/tenant-info.interface';
import { User } from '../../model/user.interface';
import * as userActions from '../actions';

export interface UserState {
  user: User;
}

export const initialState: UserState = undefined;

const loginReducer = createReducer(
  initialState,
  on(userActions.loginSuccess, (state, user) => {

    return user.user;
  })
);

export function reducer(state: UserState | undefined, action: Action): any {

  return loginReducer(state, action);
}

export const getLoggedInUser = (state: UserState) => {

  return state.user;
};