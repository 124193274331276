import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './components/navbar/navbar.component';
import { MenubarModule } from 'primeng/menubar';
import { EventPreviewComponent } from './components/event-preview/event-preview.component';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { WithCredentialsInterceptor } from './interceptor/with-credentials.interceptor';
import { TagModule } from 'primeng/tag';
import { BadgeModule } from 'primeng/badge';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { DirectoryComponent } from './components/directory/directory.component';
import { PaymentSuccessComponent } from './components/payment-success/payment-success.component';
import { FooterComponent } from './components/footer/footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { OrganiserPreviewComponent } from './components/organiser-preview/organiser-preview.component';
import { SharedModule } from '../shared/shared.module';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  declarations: [
    NavbarComponent,
    EventPreviewComponent,
    PaymentSuccessComponent,
    NotFoundComponent,
    DirectoryComponent,
    FooterComponent,
    OrganiserPreviewComponent
  ],
  imports: [
    CommonModule,
    MenubarModule,
    CardModule,
    ButtonModule,
    TagModule,
    BadgeModule,
    FontAwesomeModule,
    SharedModule,
    DropdownModule
  ],
  exports: [NavbarComponent, EventPreviewComponent, FooterComponent, HttpClientModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WithCredentialsInterceptor,
      multi: true
    }
  ]
})
export class CoreModule { }
