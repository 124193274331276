import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { CoreService } from '../../services/core.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {

  success: boolean = false;
  loading = false;

  constructor(private activatedRoute: ActivatedRoute, private coreService: CoreService, private cookieService: CookieService) {
  }

  ngOnInit(): void {

    this.loading = true;

    const paymentIntentId = this.activatedRoute.snapshot.queryParams['payment_intent'];

    this.coreService.confirmPayment(paymentIntentId).subscribe(response => {
      this.success = true;
      this.loading = false;
    },
      () => this.loading = false);
  }


}
