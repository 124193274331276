<div class="main">
  <app-navbar></app-navbar>
  <div
    class="content flex flex-column align-items-center justify-content-center"
  >
    <div class="content-section flex-grow-1">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-footer></app-footer>
</div>
