import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { TenantPublicInformation } from 'src/app/home/model/tenant-public-information.interface';
import { HomeService } from 'src/app/home/service/home.service';

@Component({
  selector: 'app-directory',
  templateUrl: './directory.component.html',
  styleUrls: ['./directory.component.scss']
})
export class DirectoryComponent implements OnInit {

  organisers$: Observable<TenantPublicInformation[]>;
  page = 0;
  more = true;

  constructor(private homeService: HomeService) {
  }

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData() {

    this.organisers$ = this.homeService.getTenants();
  }

  public trackById(index: number, item: any) {
    return item.domain;
  }

}
