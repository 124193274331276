import { CurrencyPipe, DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';
import { ContactSection, TenantInfo } from 'src/app/core/model/tenant-info.interface';
import { getOrganization } from 'src/app/core/store/reducers/organization.reducer';
import { AddressComponent, EventPublicInfo, Location, TicketsPublicInfo } from 'src/app/events/model/event-public-info.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(@Inject(DOCUMENT) private _document: Document, private _store: Store) { }

  public setOrganizationSchema(renderer2: Renderer2, organization: TenantInfo): void {

    let script = renderer2.createElement('script');
    script.type = 'application/ld+json';

    const data = {
      "@context": "https://schema.org",
      "@type": "Organization",
      "image": organization.logoUrl,
      "url": `${environment.appBaseUrl}/${organization.domain}`,
      "sameAs": [organization.web],
      "logo": organization.logoUrl,
      "name": organization.name,
      "contactPoint": this.mapToContactPoints(organization?.webConfiguration?.contact)
    };

    script.text = `${JSON.stringify(data)}`;
    renderer2.appendChild(this._document.body, script);

  }

  /**
   * Adds JSON+LD schema for events (SEO boost)
   * @param renderer2 the HTML renderer
   * @param event the event data
   */
  public setEventSchema(renderer2: Renderer2, event: EventPublicInfo): void {

    let script = renderer2.createElement('script');
    script.type = 'application/ld+json';

    this._store.select(getOrganization).subscribe((response: TenantInfo) => {

      if (!response) {
        return;
      }

      const data = {
        "@context": "https://schema.org",
        "@type": "Event",
        "name": event.description.name,
        "startDate": event.venue.startDate,
        "endDate": event.venue.endDate,
        "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
        "eventStatus": "https://schema.org/EventScheduled",
        "location": {
          "@type": "Place",
          "name": event.venue.location.name,
          "address": {
            "@type": "PostalAddress",
            "streetAddress": `${this.findAddressComponent(event.venue.location, 'route')} ${this.findAddressComponent(event.venue.location, 'street_number')}`,
            "addressLocality": this.findAddressComponent(event.venue.location, 'locality'),
            "postalCode": this.findAddressComponent(event.venue.location, 'postal_code'),
            "addressRegion": this.findAddressComponent(event.venue.location, 'administrative_area_level_2'),
            "addressCountry": this.findAddressComponent(event.venue.location, 'country')
          }
        },
        "image": [
          event.description.imageUrl
        ],
        "description": event.description.description,
        "offers": {
          "@type": "Offer",
          "url": event.url,
          "price": this.findLowerOffer(event.tickets, 'displayPrice'),
          "priceCurrency": "EUR",
          "availability": "https://schema.org/InStock",
          "validFrom": this.findLowerOffer(event.tickets, 'sellDate')
        },
        "performer": {
          "@type": "PerformingGroup",
          "name": event.description.performer || response.name || ''
        },
        "organizer": {
          "@type": "Organization",
          "name": response.name,
          "url": response.web
        }
      };

      script.text = `${JSON.stringify(data)}`;
      renderer2.appendChild(this._document.body, script);

    });
  }

  /**
   * 
   * @param location the location object
   * @param componentType the component to search
   * @returns the name of the specified component or an empty string if couldn't be found
   */
  private findAddressComponent(location: Location, componentType: string): string {

    if (!location || !location.addressComponents || location.addressComponents.length === 0) {

      return "";
    }

    let component: AddressComponent = location.addressComponents.find((component: AddressComponent) => component.types.indexOf(componentType) !== -1);

    if (!component) {

      return "";
    }

    return component.long_name ? component.long_name : component.short_name;
  }

  private findLowerOffer(tickets: TicketsPublicInfo[], componentType: string): string {

    if (!tickets || tickets.length === 0) {

      return '';
    }

    let component: TicketsPublicInfo = tickets.sort((a: TicketsPublicInfo, b: TicketsPublicInfo) => a.displayPrice - b.displayPrice)[0];

    if (!component) {

      return '';
    }

    switch (componentType) {
      case 'displayPrice':
        return (component.displayPrice / 100).toFixed(2);
      case 'sellDate':
        return '' + component.sellDate;
    }

    return '';
  }

  private mapToContactPoints(contact: ContactSection): any[] {

    let contactPoints = [];

    if (!contact) {
      return contactPoints;
    }

    if (contact.general) {
      contactPoints.push({
        "@type": "ContactPoint",
        "telephone": contact.general.phone || '',
        "email": contact.general.email || '',
      });
    }

    if (contact.management) {
      contactPoints.push({
        "@type": "ContactPoint",
        "telephone": contact.management.phone || '',
        "email": contact.management.email || '',
      });
    }

    if (contact.store) {
      contactPoints.push({
        "@type": "ContactPoint",
        "telephone": contact.store.phone || '',
        "email": contact.store.email || '',
      });
    }

    if (contact.support) {
      contactPoints.push({
        "@type": "ContactPoint",
        "telephone": contact.support.phone || '',
        "email": contact.support.email || '',
      });
    }

    return contactPoints;
  }
}
