import { Action, createReducer, on } from '@ngrx/store';
import * as actions from '../actions';

export interface TenantState {
  tenant: string;
}

export const initialState: TenantState = undefined;

const tenantReducer = createReducer(
  initialState,
  on(actions.tenantSuccess, (state, tenant) => {

    return tenant.tenant;
  })
);

export function reducer(state: TenantState | undefined, action: Action): any {

  return tenantReducer(state, action);
}

export const getTenant = (state: TenantState) => {

  return state.tenant;
}