import { Component, Input, OnInit } from '@angular/core';
import { faInstagram, faSpotify, faTiktok, faTwitch, faFacebookF, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { TenantPublicInformation } from 'src/app/home/model/tenant-public-information.interface';

@Component({
  selector: 'app-organiser-preview',
  templateUrl: './organiser-preview.component.html',
  styleUrls: ['./organiser-preview.component.scss']
})
export class OrganiserPreviewComponent implements OnInit {

  @Input() organiser: TenantPublicInformation;

  faGlobe = faGlobe;
  faInstagram = faInstagram;
  faSpotify = faSpotify;
  faTiktok = faTiktok;
  faTwitch = faTwitch;
  faFacebook = faFacebookF;
  faYoutube = faYoutube;

  constructor() { }

  ngOnInit(): void {
  }

}
