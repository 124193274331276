import { Component, OnDestroy, OnInit } from '@angular/core';
import { State } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TenantInfo } from '../../model/tenant-info.interface';
import {
  faInstagram,
  faSpotify,
  faTiktok,
  faTwitch,
  faFacebookF,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  state$: Subscription;
  organization: TenantInfo;
  faGlobe = faGlobe;
  faInstagram = faInstagram;
  faSpotify = faSpotify;
  faTiktok = faTiktok;
  faTwitch = faTwitch;
  faFacebook = faFacebookF;
  faYoutube = faYoutube;

  name: string = '';
  tenant: string = undefined;

  items: MenuItem[] = [];
  selectedLanguage: string = 'es';

  languages = [
    { code: 'es', label: '🇪🇸 Español' },
    { code: 'en', label: '🇬🇧 English' },
    { code: 'pt', label: '🇵🇹 Português' }
  ];

  constructor(private state: State<any>, private translate: TranslateService) {}

  ngOnInit(): void {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    this.selectedLanguage = savedLanguage ? savedLanguage : 'es';
    this.translate.use(this.selectedLanguage);

    this.state$ = this.state.subscribe((state) => {
      if (state.organization) {
        this.organization = state.organization;
      }
      if (state.tenant) {
        this.tenant = state.tenant;
        this.updateMenuItems();
      }
      if (state.user) {
        this.name = state.user.name;
        this.updateMenuItems();
      }
    });
    
  }

  ngOnDestroy() {
    if (this.state$) {
      this.state$.unsubscribe();
    }
  }

  switchLanguage(language: string) {
    this.selectedLanguage = language;
    this.translate.use(language);
    localStorage.setItem('selectedLanguage', language);
    this.updateMenuItems();
  }

  private updateMenuItems() {
    this.translate
      .get(['EXPERIENCES', 'DISCOVER', 'MY_PROFILE'])
      .subscribe((translations) => {
        this.items = [
          {
            label: translations['EXPERIENCES'] || 'Experiencias',
            routerLink: [this.tenant + '/events'],
          },
          {
            label: translations['DISCOVER'] || 'Descubre',
            routerLink: [this.tenant + '/store'],
          },
          {
            label: this.name
              ? this.name
              : translations['MY_PROFILE'] || 'Mi perfil',
            url: environment.profile.url,
            replaceUrl: true,
            disabled: false,
          },
          {
            label: this.getLanguageEmoji(this.selectedLanguage),
            items: this.languages.map(lang => ({
              label: lang.label,
              command: () => this.switchLanguage(lang.code)
            }))
          }
        ];
      });
  }

  private getLanguageEmoji(code: string): string {
    const language = this.languages.find(lang => lang.code === code);
    return language ? language.label.split(' ')[0] : '🌐';
  }

}
