import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { EventPreview } from 'src/app/events/model/event-preview.interface';
import { environment } from 'src/environments/environment';
import { MerchPreview } from '../model/merch-preview.interface';
import { TenantPublicInformation } from '../model/tenant-public-information.interface';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  urlBase: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getNextEvents() {

    const url = `${this.urlBase}/public/events?limit=5`

    return this.http.get<EventPreview[]>(url);

  }

  getFeaturedMerch(limit: number = 5): Observable<MerchPreview[]> {

    const url = `${this.urlBase}/public/merch?limit=${limit}`;

    return this.http.get<MerchPreview[]>(url);

  }

  getDirectory(page: number) {

    const url = `${this.urlBase}/public/events/directory?page=${page}`;

    return this.http.get<any>(url);

  }

  getTenants(): Observable<TenantPublicInformation[]> {

    const url = `${this.urlBase}/public/organisers`;

    return this.http.get<TenantPublicInformation[]>(url);

  }
}
