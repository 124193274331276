<div class="card">
  <div class="card-container flex align-items-center justify-content-center">
    <div class="text-center">
      <i class="pi pi-check-circle" style="color: green; font-size: 4rem"></i>
      <div *ngIf="success && !loading" class="text-center mb-5">
        <h3>{{ 'PAYMENT_SUCCESS' | translate }}</h3>
        <p>{{ 'CLOSE_WINDOW' | translate }}</p>
      </div>
      <div *ngIf="!success && !loading" class="text-center mb-5">
        <h3>{{ 'PAYMENT_FAILED' | translate }}</h3>
        <p>{{ 'TRY_AGAIN' | translate }}</p>
      </div>
      <div *ngIf="loading" class="text-center mb-5">
        <h3>{{ 'LOADING' | translate }}</h3>
      </div>
    </div>
  </div>
</div>
